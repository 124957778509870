import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Navbar } from "react-bootstrap"
import logo from "../images/digital-desk--white.svg"


const Header = ({ siteTitle }) => {
  return (
    <header>
      <Navbar bg="primary" variant="dark" sticky="top" expand="md" collapseOnSelect>
        <Link to="/">
          <Navbar.Brand>
            <img alt={siteTitle} src={logo} className="header__logo" />
          </Navbar.Brand>
        </Link>
      </Navbar>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
