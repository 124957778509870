import React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from "react-bootstrap"
import { format } from "date-fns"
import logo from "../images/digital-desk.svg"


const Footer = ({ siteTitle }) => {
  return (
    <footer className={`footer py-2 bg-light border-top border-primary`}>
      <Container fluid>
        <Row className="align-items-center">
          <Col xs="auto">
            <img alt={siteTitle} src={logo} className="footer__logo" />
          </Col>
          <Col>
            <p className="m-0 text-right py-2 text-muted">&copy; Copyright {format(new Date(), "yyyy")} Esparter. All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
)}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}


export default Footer
